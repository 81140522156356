export default class Loader {
	constructor(el) {
		this.el = el;

		// Store default element values
		this.textWrapper = this.el;
		this.text = this.textWrapper.innerText;
		this.style = getComputedStyle(this.el);

		this.width = this.el.getBoundingClientRect().width;
		this.height = this.el.getBoundingClientRect().height;

		this.borderColor = this.style.borderColor;
		this.backgroundColor = this.style.backgroundColor;
		this.background = this.style.background;

		this.el.style.minWidth = this.width + 'px';
		this.el.style.minHeight = this.height + 'px';
	}

	load() {
		this.textWrapper.innerText = '';
		this.el.style.width = this.width + 'px';
		this.el.style.height = this.height + 'px';
		this.el.style.display = 'flex';
		this.el.style.justifyContent = 'center';
		this.el.style.alignItems = 'center';
		this.el.style.pointerEvents = 'none';

		var loader = document.createElement('div');
		loader.classList.add('loader');

		loader.style.content = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='margin: auto; background: none; display: block; shape-rendering: auto;' width='200px' height='200px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Ccircle cx='50' cy='50' fill='none' stroke='${this.style.borderColor}' stroke-width='15' r='40' stroke-dasharray='188.49555921538757 64.83185307179586'%3E%3CanimateTransform attributeName='transform' type='rotate' repeatCount='indefinite' dur='0.78125s' values='0 50 50;360 50 50' keyTimes='0;1'/%3E%3C/circle%3E%3C!-- %5Bldio%5D generated by https://loading.io/ --%3E%3C/svg%3E")`;

		loader.style.height = `${this.height - 5}px`;
		loader.style.width = `${this.height - 5}px`;

		this.el.appendChild(loader);
		this.el.classList.add('disabled');
	}

	success(message) {
		this.textWrapper.innerText = message;
		this.removeLoader();
	}

	error(message = '', delay = 0) {
		this.textWrapper.innerText = message;

		setTimeout(() => {
			this.el.classList.remove('disabled');
			this.removeLoader();
			this.resetLabel();
		}, delay);
	}

	remove() {
		this.el.parentElement.removeChild(this.el);
	}

	removeLoader() {
		this.el.classList.remove('loading');
		var loader = this.el.querySelector('.loader');

		this.resetSize();
		this.resetStyle();

		if (loader) loader.parentElement.removeChild(loader);
	}

	reset() {
		this.resetLabel();
		this.resetSize();
		this.resetStyle();

		this.el.classList.remove('disabled');
	}

	resetLabel() {
		this.textWrapper.innerText = this.text;
	}

	resetSize() {
		this.el.style.width = null;
		this.el.style.height = null;
	}

	resetStyle() {
		this.el.style.width = null;
		this.el.style.height = null;
		this.el.style.display = null;
		this.el.style.justifyContent = null;
		this.el.style.alignItems = null;
		this.el.style.pointerEvents = null;
	}
}
